// core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// store
import { getBlocks, getRoadmap } from "../../store/modules/blocks/services";
import { getGeneralData } from "../../store/modules/general-data/services";
import { getNews } from "../../store/modules/news/services";
import {
  selectBlocks,
  selectRoadmap,
} from "../../store/modules/blocks/selectors";
import { selectGeneralData } from "../../store/modules/general-data/selectors";
import { selectVariables } from "../../store/modules/variables/selectors";
import { selectNews } from "../../store/modules/news/selectors";

// utils

// components
import PageLoader from "../../components/atoms/PageLoader";
import HomeMain from "./HomeMain";
import MarketServices from "../../components/features/MarketServices";
import AboutToken from "../../components/features/AboutToken";
import HomeIcons from "./HomeIcons";
import NetContracts from "../../components/features/NetContracts";
import HomeReliability from "./HomeReliability";
import HomeSecurity from "./HomeSecurity";
import HomeNews from "./HomeNews";
import HomeCTA from "./HomeCTA";
import HomeWelcome from "./HomeWelcome";
import HomeFaqs from "./HomeFaqs";
import UsageSlides from "../../components/features/UsageSlides";

// styles
import "./index.scss";

import AOS from "aos";

const Home = () => {
  // store
  const dispatch = useDispatch();
  const blocks = useSelector(selectBlocks);
  const generalData = useSelector(selectGeneralData);
  const variables = useSelector(selectVariables);
  const news = useSelector(selectNews);
  const roadmap = useSelector(selectRoadmap);

  const location = useLocation();

  useEffect(() => {
    dispatch(getBlocks());
    dispatch(getGeneralData());
    dispatch(getRoadmap());
    dispatch(getNews({ per_page: 10, page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      blocks.isSuccess &&
      generalData.isSuccess &&
      variables.isSuccess &&
      news.isSuccess &&
      roadmap.isSuccess
    ) {
      AOS.init({
        offset: 200,
        duration: 1000,
        easing: "ease-out-cubic",
      });

      if (location.hash) {
        let anchor = location.hash.slice(1);
        let elem = document.getElementById(anchor);

        setTimeout(() => {
          if (elem) {
            window.scrollTo({ top: elem.offsetTop, behavior: "smooth" });
          }
        }, 300);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    blocks.isSuccess,
    generalData.isSuccess,
    variables.isSuccess,
    news.isSuccess,
    roadmap.isSuccess,
  ]);

  // @ts-ignore
  return (
    <div className="home">
      {blocks.isSuccess &&
      generalData.isSuccess &&
      variables.isSuccess &&
      roadmap.isSuccess &&
      news.isSuccess ? (
        <>
          <div className="home__oveflow">
            <HomeMain data={blocks.data.main} />
            <MarketServices generalData={generalData.data.services} />
          </div>
          <UsageSlides generalData={generalData.data.usage_slides} />
          <AboutToken
            data={blocks.data.about_token}
            variables={variables.data}
          />
          <NetContracts generalData={generalData.data.contracts} />
          <HomeIcons generalData={generalData.data.icons} />
          <HomeSecurity data={blocks.data.about_security} />
          <HomeReliability data={blocks.data.reliability} />
          {news.data?.length > 0 && <HomeNews data={news.data} />}
          <HomeCTA data={blocks.data.begin_adventure} />
          <HomeWelcome variables={variables.data} />
          <HomeFaqs
            data={blocks.data.faq}
            generalData={generalData.data.faqs}
          />
        </>
      ) : (
        <PageLoader active={true} />
      )}
    </div>
  );
};

export default Home;
