// core
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import { Link as RouterLink } from "react-router-dom";
import ScrollTrigger from "react-scroll-trigger";

// utils
// components
import InfoCircle from "../../icons/InfoCircle";
import AppButton from "../../atoms/Button";
import Card from "../../icons/Card";

// styles
import "./index.scss";

const UsageSlides = ({ generalData }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [activeSlide, setActiveSlide] = useState(1);
  const slideCount = generalData.length;
  const scrollRange = Number((1 / (slideCount + 1)).toFixed(2));
  const onEnterViewport = () => {};

  const onExitViewport = () => {};
  const onProgressViewport = ({ progress }) => {
    //progress 0 - start / 1 -end

    for (let i = 0; i < slideCount; i++) {
      let min = i * scrollRange;
      let max = (i + 1) * scrollRange;
      if (progress >= min && progress <= max) {
        let newSlide = i + 1;
        if (activeSlide !== newSlide) {
          setActiveSlide(newSlide);
        }
      }
    }
  };

  return (
    <div className="usage-slides">
      <div className="app__wrapper">
        <div className="usage-slides__title">
          {t("blocks.usageSlides.usageSlidesTitle")}
        </div>
        {width > 768 ? (
          <ScrollTrigger
            onEnter={onEnterViewport}
            onExit={onExitViewport}
            onProgress={onProgressViewport}
            className="usage-slides__scroll"
            style={{
              height: `calc((100vh - ${
                width > 1024 ? 146 : 114
              }px) * ${slideCount} )`,
            }}
          >
            <div className="usage-slides__grid">
              <div className="usage-slides__images">
                {generalData.map((item, index) => (
                  <div
                    key={item.id}
                    className={
                      `usage-slides__image` +
                      (activeSlide === index + 1
                        ? " usage-slides__image--active"
                        : "")
                    }
                  >
                    <img src={item.image} title={item.title} />
                  </div>
                ))}
              </div>
              <div className="usage-slides__infos">
                {generalData.map((item, index) => (
                  <div
                    key={item.id}
                    className={
                      "usage-slides__info" +
                      (activeSlide === index + 1
                        ? " usage-slides__info--active"
                        : "")
                    }
                  >
                    <div className="usage-slides__info-head">{item.title}</div>
                    <div className="usage-slides__info-body">
                      <div className="usage-slides__info-content">
                        {item.description && (
                          <div
                            className="usage-slides__info-text"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        )}
                        {item.cards?.length > 0 && (
                          <>
                            {item.cards.map((card) => (
                              <div
                                key={card.id}
                                className="usage-slides__info-card"
                              >
                                <img
                                  src={card.image}
                                  title={card.title}
                                  className="usage-slides__info-card-img"
                                />
                                <div className="usage-slides__info-card-title">
                                  {card.title}
                                </div>
                                {card.description && (
                                  <div className="usage-slides__info-card-desc">
                                    {card.description}
                                  </div>
                                )}
                              </div>
                            ))}
                          </>
                        )}

                        {item.has_url && (
                          <>
                            {item.url ? (
                              <AppButton
                                className="app-button--fill-3 usage-slides__info-btn"
                                href={item.url}
                                prepend={<Card />}
                              >
                                {item.button_title}
                              </AppButton>
                            ) : (
                              <AppButton
                                className="app-button--fill-3 usage-slides__info-btn"
                                to={"/weld-card"}
                                prepend={<Card />}
                              >
                                {item.button_title}
                              </AppButton>
                            )}
                          </>
                        )}
                        <div
                          className="usage-slides__info-note"
                          dangerouslySetInnerHTML={{
                            __html: t("blocks.usageSlides.usageSlidesWarnNote"),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </ScrollTrigger>
        ) : (
          <div className="usage-slides__list">
            {generalData.map((item) => (
              <div key={item.id} className="usage-slides__item">
                <div className="usage-slides__info-head">{item.title}</div>
                <div className="usage-slides__image">
                  <img src={item.image} title={item.title} />
                </div>
                <div className="usage-slides__info">
                  <div className="usage-slides__info-body">
                    <div className="usage-slides__info-content">
                      {item.description && (
                        <div
                          className="usage-slides__info-text"
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        />
                      )}
                      {item.cards?.length > 0 && (
                        <>
                          {item.cards.map((card) => (
                            <div
                              key={card.id}
                              className="usage-slides__info-card"
                            >
                              <img
                                src={card.image}
                                title={card.title}
                                className="usage-slides__info-card-img"
                              />
                              <div className="usage-slides__info-card-title">
                                {card.title}
                              </div>
                              {card.description && (
                                <div className="usage-slides__info-card-desc">
                                  {card.description}
                                </div>
                              )}
                            </div>
                          ))}
                        </>
                      )}

                      {item.has_url && (
                        <>
                          {item.url ? (
                            <AppButton
                              className="app-button--fill-3 usage-slides__info-btn"
                              href={item.url}
                              prepend={<Card />}
                            >
                              {item.button_title}
                            </AppButton>
                          ) : (
                            <AppButton
                              className="app-button--fill-3 usage-slides__info-btn"
                              to={"/weld-card"}
                              prepend={<Card />}
                            >
                              {item.button_title}
                            </AppButton>
                          )}
                        </>
                      )}
                      <div
                        className="usage-slides__info-note"
                        dangerouslySetInnerHTML={{
                          __html: t("blocks.usageSlides.usageSlidesWarnNote"),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UsageSlides;
