// core
import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import * as Yup from "yup";
// utils

// components
import HomeMainBgImg from "../../../assets/images/home-main-bg.png";
import AppButton from "../../../components/atoms/Button";
import ExchangeModal from "../../../components/features/ExchangeModal";
import QuestionCircle from "../../../components/icons/QuestionCircle";
import UahgText from "../../../components/icons/UahgText";
import LogoSimple from "../../../components/icons/LogoSimple";
import UahSign from "../../../components/icons/UahSign";
import HomeMainBg from "../../../components/icons/HomeMainBg";

// styles
import "./index.scss";

// data
import { INITIAL_DATA } from "./data.js";
import HomeMainLogo from "../../../components/icons/HomeMainLogo";
import HomeMainIcon1 from "../../../components/icons/HomeMainIcon1";
import HomeMainIcon2 from "../../../components/icons/HomeMainIcon2";
import HomeMainIcon5 from "../../../components/icons/HomeMainIcon5";
import HomeMainIcon3 from "../../../components/icons/HomeMainIcon3";
import HomeMainIcon4 from "../../../components/icons/HomeMainIcon4";
import HomeMainIcon6 from "../../../components/icons/HomeMainIcon6";

const HomeMain = ({ data }) => {
  const { t, i18n } = useTranslation();
  const { width } = useWindowSize();
  const formikRef = useRef();
  const [isActiveModal, setIsActiveModal] = useState(false);

  const CURRENCY_REGEX = /^\d+(\.\d{1,2})?$/gm;
  const localization =
    i18n.language && i18n.language.length > 2
      ? i18n.language.slice(0, 2)
      : i18n.language;

  const FormValidation = Yup.object().shape({
    amount: Yup.string()
      .matches(CURRENCY_REGEX, {
        message: t("validation.isCurrency"),
      })
      .required(t("validation.required")),
  });
  const onSendHandler = () => {
    window.open(`https://whiteex.com/${localization}/#mainForm`, "_blank");
  };

  const openModalHandler = () => {
    setIsActiveModal(true);
  };

  const closeModalHandler = () => {
    setTimeout(() => setIsActiveModal(false), 400);
  };

  return (
    <div className="home-main">
      <img src={HomeMainBgImg} className="home-main__bg" />
      <div className="app__wrapper">
        <div className="home-main__grid">
          <div
            className="home-main__info"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="home-main__title">{data.title}</div>
            {data.description && (
              <div className="home-main__desc">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.description,
                  }}
                />
              </div>
            )}
            {width > 768 && (
              <>
                <div className="home-main__actions">
                  <Formik
                    enableReinitialize={true}
                    initialValues={INITIAL_DATA}
                    onSubmit={onSendHandler}
                    validationSchema={FormValidation}
                    innerRef={formikRef}
                  >
                    {({ errors, touched }) => (
                      <Form className="app-form">
                        <div className="app-form__field app-form__field--input">
                          <label>{t("landing.inputSumLabel")}</label>
                          <div className="app-form__group">
                            <Field
                              type="text"
                              name="amount"
                              placeholder={"1 000 UAH"}
                              autoComplete="chrome-off"
                              className={
                                "home-cta__input" +
                                (errors.amount && touched.amount
                                  ? " app-form__field--error"
                                  : "")
                              }
                            />
                            <AppButton
                              disabled={Object.keys(errors).length}
                              type={"submit"}
                              className="app-button--fill-3 home-main__btn"
                            >
                              {t("actions.buyUahg")}
                            </AppButton>
                          </div>

                          <ErrorMessage
                            name="amount"
                            component="div"
                            className="app-form__field-error"
                          />

                          <div className="home-main__note">
                            {t("home.mainNote")}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="home-main__calc">
                  <div className="home-main__calc-item">
                    <UahSign />1 <span>UAH</span>
                  </div>
                  <div className="home-main__calc-item home-main__calc-item--sign">
                    <svg
                      width="17"
                      height="15"
                      viewBox="0 0 17 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.72844 6.82353C0.72844 5.80606 0.883272 4.84942 1.19294 3.9536C1.51366 3.05779 2.01134 2.33892 2.68596 1.79701C3.37165 1.24404 4.26747 0.967551 5.37341 0.967551C6.16969 0.967551 6.87197 1.07815 7.48024 1.29933C8.08851 1.52052 8.64701 1.76383 9.15575 2.02926C9.56495 2.22833 9.96309 2.40528 10.3502 2.56011C10.7373 2.71494 11.1243 2.79236 11.5114 2.79236C12.0644 2.79236 12.4625 2.62647 12.7058 2.29468C12.9602 1.95184 13.1095 1.50946 13.1537 0.967551H16.3886C16.3886 1.99608 16.2338 2.95825 15.9241 3.85407C15.6255 4.73883 15.1334 5.45769 14.4477 6.01066C13.7731 6.55258 12.8662 6.82353 11.7271 6.82353C10.9529 6.82353 10.2506 6.71294 9.62025 6.49175C9.00092 6.2595 8.42582 6.01619 7.89497 5.76183C7.48577 5.56276 7.08763 5.39133 6.70055 5.24756C6.31347 5.09273 5.93192 5.01531 5.55589 5.01531C5.02504 5.01531 4.63796 5.18673 4.39465 5.52958C4.1624 5.86136 4.0131 6.29268 3.94674 6.82353H0.72844ZM0.72844 14.2389C0.72844 13.2104 0.883272 12.2537 1.19294 11.369C1.51366 10.4732 2.01134 9.75429 2.68596 9.21238C3.37165 8.6594 4.26747 8.38292 5.37341 8.38292C6.16969 8.38292 6.87197 8.49351 7.48024 8.7147C8.08851 8.93589 8.64701 9.1792 9.15575 9.44463C9.56495 9.6437 9.96309 9.82065 10.3502 9.97548C10.7373 10.1303 11.1243 10.2077 11.5114 10.2077C12.0644 10.2077 12.4625 10.0418 12.7058 9.71005C12.9602 9.36721 13.1095 8.92483 13.1537 8.38292H16.3886C16.3886 9.41145 16.2338 10.3736 15.9241 11.2694C15.6255 12.1542 15.1334 12.8731 14.4477 13.426C13.7731 13.9679 12.8662 14.2389 11.7271 14.2389C10.9529 14.2389 10.2506 14.1283 9.62025 13.9071C9.00092 13.6749 8.42582 13.4316 7.89497 13.1772C7.48577 12.9781 7.08763 12.8067 6.70055 12.6629C6.31347 12.5081 5.93192 12.4307 5.55589 12.4307C5.02504 12.4307 4.63796 12.6021 4.39465 12.9449C4.1624 13.2767 4.0131 13.708 3.94674 14.2389H0.72844Z"
                        fill="#444040"
                      />
                    </svg>
                  </div>
                  <div className="home-main__calc-item">
                    <LogoSimple />
                    1
                    <UahgText className="home-main__calc-uahg" />
                  </div>

                  <div
                    className="home-main__calc-btn"
                    onClick={() => {
                      openModalHandler();
                    }}
                  >
                    <QuestionCircle />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="home-main__banner">
            <HomeMainBg
              className="home-main__banner-bg"
              data-aos="custom-zoom-in"
              data-aos-delay="100"
              data-aos-duration="5000"
              data-aos-easing="ease-out-quad"
            />
            <HomeMainLogo className="home-main__banner-logo" />
            <HomeMainIcon1
              className="home-main__banner-icon home-main__banner-icon--1"
              data-aos="fade"
              data-aos-delay="2750"
            />
            <HomeMainIcon2
              className="home-main__banner-icon home-main__banner-icon--2"
              data-aos="fade"
              data-aos-delay="3100"
            />
            <HomeMainIcon3
              className="home-main__banner-icon home-main__banner-icon--3"
              data-aos="fade"
              data-aos-delay="3450"
            />
            <HomeMainIcon4
              className="home-main__banner-icon home-main__banner-icon--4"
              data-aos="fade"
              data-aos-delay="3800"
            />
            <HomeMainIcon5
              className="home-main__banner-icon home-main__banner-icon--5"
              data-aos="fade"
              data-aos-delay="4150"
            />
            <HomeMainIcon6
              className="home-main__banner-icon home-main__banner-icon--6"
              data-aos="fade"
              data-aos-delay="4500"
            />
          </div>
          {width <= 768 && (
            <>
              <div
                className="home-main__actions"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <Formik
                  enableReinitialize={true}
                  initialValues={INITIAL_DATA}
                  onSubmit={onSendHandler}
                  validationSchema={FormValidation}
                  innerRef={formikRef}
                >
                  {({ errors, touched }) => (
                    <Form className="app-form">
                      <div className="app-form__field app-form__field--input">
                        <label>{t("landing.inputSumLabel")}</label>
                        <div className="app-form__group">
                          <Field
                            type="text"
                            name="amount"
                            placeholder={"1 000 UAH"}
                            autoComplete="chrome-off"
                            className={
                              "home-cta__input" +
                              (errors.amount && touched.amount
                                ? " app-form__field--error"
                                : "")
                            }
                          />
                          <AppButton
                            disabled={Object.keys(errors).length}
                            type={"submit"}
                            className="app-button--fill-3 home-main__btn"
                          >
                            {t("actions.buyUahg")}
                          </AppButton>
                        </div>

                        <ErrorMessage
                          name="amount"
                          component="div"
                          className="app-form__field-error"
                        />

                        <div className="home-main__note">
                          {t("home.mainNote")}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div
                className="home-main__calc"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="home-main__calc-item">
                  <UahSign />1 <span>UAH</span>
                </div>
                <div className="home-main__calc-item home-main__calc-item--sign">
                  <svg
                    width="17"
                    height="15"
                    viewBox="0 0 17 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.72844 6.82353C0.72844 5.80606 0.883272 4.84942 1.19294 3.9536C1.51366 3.05779 2.01134 2.33892 2.68596 1.79701C3.37165 1.24404 4.26747 0.967551 5.37341 0.967551C6.16969 0.967551 6.87197 1.07815 7.48024 1.29933C8.08851 1.52052 8.64701 1.76383 9.15575 2.02926C9.56495 2.22833 9.96309 2.40528 10.3502 2.56011C10.7373 2.71494 11.1243 2.79236 11.5114 2.79236C12.0644 2.79236 12.4625 2.62647 12.7058 2.29468C12.9602 1.95184 13.1095 1.50946 13.1537 0.967551H16.3886C16.3886 1.99608 16.2338 2.95825 15.9241 3.85407C15.6255 4.73883 15.1334 5.45769 14.4477 6.01066C13.7731 6.55258 12.8662 6.82353 11.7271 6.82353C10.9529 6.82353 10.2506 6.71294 9.62025 6.49175C9.00092 6.2595 8.42582 6.01619 7.89497 5.76183C7.48577 5.56276 7.08763 5.39133 6.70055 5.24756C6.31347 5.09273 5.93192 5.01531 5.55589 5.01531C5.02504 5.01531 4.63796 5.18673 4.39465 5.52958C4.1624 5.86136 4.0131 6.29268 3.94674 6.82353H0.72844ZM0.72844 14.2389C0.72844 13.2104 0.883272 12.2537 1.19294 11.369C1.51366 10.4732 2.01134 9.75429 2.68596 9.21238C3.37165 8.6594 4.26747 8.38292 5.37341 8.38292C6.16969 8.38292 6.87197 8.49351 7.48024 8.7147C8.08851 8.93589 8.64701 9.1792 9.15575 9.44463C9.56495 9.6437 9.96309 9.82065 10.3502 9.97548C10.7373 10.1303 11.1243 10.2077 11.5114 10.2077C12.0644 10.2077 12.4625 10.0418 12.7058 9.71005C12.9602 9.36721 13.1095 8.92483 13.1537 8.38292H16.3886C16.3886 9.41145 16.2338 10.3736 15.9241 11.2694C15.6255 12.1542 15.1334 12.8731 14.4477 13.426C13.7731 13.9679 12.8662 14.2389 11.7271 14.2389C10.9529 14.2389 10.2506 14.1283 9.62025 13.9071C9.00092 13.6749 8.42582 13.4316 7.89497 13.1772C7.48577 12.9781 7.08763 12.8067 6.70055 12.6629C6.31347 12.5081 5.93192 12.4307 5.55589 12.4307C5.02504 12.4307 4.63796 12.6021 4.39465 12.9449C4.1624 13.2767 4.0131 13.708 3.94674 14.2389H0.72844Z"
                      fill="#444040"
                    />
                  </svg>
                </div>
                <div className="home-main__calc-item">
                  <LogoSimple />
                  1
                  <UahgText className="home-main__calc-uahg" />
                </div>

                <div
                  className="home-main__calc-btn"
                  onClick={() => {
                    openModalHandler();
                  }}
                >
                  <QuestionCircle />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {isActiveModal &&
        ReactDOM.createPortal(
          <ExchangeModal
            isActive={isActiveModal}
            data={data}
            onClose={closeModalHandler}
          />,
          document.getElementById("modal")
        )}
    </div>
  );
};

export default HomeMain;
