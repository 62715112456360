// core
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";

// store

// components
import AppButton from "../../../components/atoms/Button";
import HomeMainBg from "../../../components/icons/HomeMainBg";

// styles
import "./index.scss";

const TrustWalletBanner = ({ data }) => {
  // store
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div className="trust-wallet-banner">
      <div className="app__wrapper">
        <div className="trust-wallet-banner__grid">
          <div
            className="trust-wallet-banner__content"
            data-aos="fade-up"
            data-aos-delay="900"
          >
            <div className="trust-wallet-banner__title">{data.title}</div>
            <div
              className="trust-wallet-banner__desc"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            {width > 768 && (
              <>
                {data.link && (
                  <div className="trust-wallet-banner__actions">
                    <AppButton
                      className="app-button--fill-3 weld__btn"
                      href={data.link}
                      target="_blank"
                    >
                      {data.button_title}
                    </AppButton>
                  </div>
                )}
                {data.note && (
                  <div
                    className="trust-wallet-banner__note"
                    dangerouslySetInnerHTML={{ __html: data.note }}
                  />
                )}
              </>
            )}
          </div>
          <div className="trust-wallet-banner__imgs">
            <HomeMainBg
              className="trust-wallet-banner__imgs-bg"
              data-aos="custom-zoom-in"
              data-aos-delay="200"
              data-aos-duration="1200"
              data-aos-easing="ease-out-quad"
            />
            <img
              src={data.image}
              className="trust-wallet-banner__img"
              data-aos="fade"
              data-aos-delay="1200"
              data-aos-duration="2000"
            />
          </div>

          {width <= 768 && (
            <>
              {data.link && (
                <div className="trust-wallet-banner__actions">
                  <AppButton
                    className="app-button--fill-3 weld__btn"
                    href={data.link}
                    target="_blank"
                  >
                    {data.button_title}
                  </AppButton>
                </div>
              )}
              {data.note && (
                <div
                  className="trust-wallet-banner__note"
                  dangerouslySetInnerHTML={{ __html: data.note }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrustWalletBanner;
